/**
 * inbody JS
 *
 * siehe webpack.mix.js
 *
 */

require('../app.js');

$('.slick').slick({
    centerMode: true,
    centerPadding: '30px',
    slidesToShow: 1,
    autoplay: true,
    accessibility: false,
    touchMove:false,
    swipe:false,
    autoplaySpeed: 0,
    speed: 4000,
    cssEase:'linear',
    arrows: false,
    variableWidth: true,
    infinite: true,
});


// datepicker lokalisiert
$.fn.datepicker.dates['de'] = {
    days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    daysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
    daysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    today: "Heute",
    monthsTitle: "Monate",
    clear: "Löschen",
    weekStart: 1,
    format: "dd.mm.yyyy"
};

$('.datepicker').datepicker({
    language: 'de',
    defaultDate: new Date('1980-01-01'), // TODO: das geht nicht
    endDate: new Date(new Date().setDate(new Date().getDate())),
    //startView: 2
});

// zahlen  valideren
function forceNumeric($input){
    $input.val($input.val().replace(/[^\d\,\.]+/g,''));
}

// alter aus deutschen Geburtsdatum berechnen
function getAge(dateString) {
    var today = new Date();
    var birthdayArray = dateString.split(".");
    var birthday = new Date(birthdayArray[2], birthdayArray[1] - 1, birthdayArray[0]);
    var age = today.getFullYear() - birthday.getFullYear();
    var m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
        age--;
    }
    return age;
}

// bmi rechner
$('#height, #weight').on( 'propertychange input', function() {

    // nur 0-9 punkt und komma erlauben
    forceNumeric($('#weight'));
    forceNumeric($('#height'));

    // eingaben als float
    let weight = parseFloat($('#weight').val().toString().replace(',','.'));
    let height = parseFloat($('#height').val().toString().replace(',','.'));

    // bmi ausrechnen
    let bmi = ((weight / Math.pow(height, 2)).toFixed(2).toString());

    // nur ordentliche ergebnisse anzeigen
    if(isNaN(bmi) || ! isFinite(bmi)) {
        $('#bmi').val('');
        $('#bmi').next().find('strong').text('');
    }
    else {
        bmi = bmi.replace('.',',');
        $('#bmi').val(bmi);
        $('#bmi').next().find('strong').text(bmi);
    }

    // grundumsatz ist abhänging
    refreshMetabolicRate();

})

// WHR rechner
$('#waist, #hip').on( 'propertychange input', function() {

    // nur 0-9 punkt und komma erlauben
    forceNumeric($('#waist'));
    forceNumeric($('#hip'));

    // eingaben als float
    let waistSize = parseFloat($('#waist').val().toString().replace(',','.'));
    let HipSize = parseFloat($('#hip').val().toString().replace(',','.'));

    // WHR ausrechnen
    let whr = (waistSize / HipSize).toFixed(2).toString();

    // nur ordentliche ergebnisse anzeigen
    if(isNaN(whr) || ! isFinite(whr)) {
        $('#whr').val('');
        $('#whr').next().find('strong').text('');
    }
    else {
        whr = whr.replace('.',',')
        $('#whr').val(whr);
        $('#whr').next().find('strong').text(whr);
    }
})

const refreshMetabolicRate = function() {

    forceNumeric($('#height'));
    forceNumeric($('#weight'));

    // eingaben als float
    let weight = parseFloat($('#weight').val().toString().replace(',','.'));
    let height = parseFloat($('#height').val().toString().replace(',','.'));
    let birthday = $('#birthday').val();
    let age = getAge(birthday);
    let sex = $('.sex:checked').val();
    let metabolicRate = 0.0;

    if(sex == 'male') {
        metabolicRate = parseFloat(66.47 + (13.75 * weight) + (5 * height) - (6.76 * age));
    }
    else if(sex == 'female') {
        metabolicRate = parseFloat(655.1 + (9.6 * weight) + (1.85 * height) - (4.68 * age));
    }

    // ergebnis schön machen
    metabolicRate = metabolicRate.toFixed(0).toString().replace('/./g',',');

    // nur ordentliche ergebnisse anzeigen
    if(isNaN(metabolicRate) || ! isFinite(metabolicRate)) {
        $('#metabolic_rate').val('');
        $('#metabolic_rate').next().val('');
    }
    else {
        metabolicRate.replace('.',',')
        $('#metabolic_rate').next().find('strong').text(metabolicRate + ' kcal/Tag');
        $('#metabolic_rate').val(metabolicRate);
    }

    // leistungsumsatz ist abhängig
    refreshPerformanceTurnover(metabolicRate);
}

// Grundumsatz ausrechnen
$('#birthday, .sex').on( 'propertychange input changeDate', function() {
    refreshMetabolicRate();
});

// Leistungsumsazu ausrechnen (Das mit dem Dropdown)
const refreshPerformanceTurnover = function (metabolicRate) {
    const pal = parseFloat($('#pal_wert').val());

    let performanceTurnover = metabolicRate * pal;

    // ergebnis schön machen
    performanceTurnover = performanceTurnover.toFixed(0).toString().replace('/./g',',');

    // nur ordentliche ergebnisse anzeigen
    if(isNaN(performanceTurnover) || ! isFinite(performanceTurnover)) {
        $('#performance_turnover').val('');
        $('#performance_turnover').next().val('');
    }
    else {
        metabolicRate.replace('.',',')
        $('#performance_turnover').next().find('strong').text(performanceTurnover + ' kcal/Tag');
        $('#performance_turnover').val(performanceTurnover);
    }
}
$('#pal_wert').on( 'propertychange input changeDate', function() {
    refreshPerformanceTurnover($('#metabolic_rate').val());
});

// Gewichtsänderung ausrechnen
$('#new_weight').on( 'propertychange input changeDate', function() {

    forceNumeric($('#new_weight'));

    // eingaben als float
    let newWeight = parseFloat($('#new_weight').val().toString().replace(',','.'));

    let weightChange = newWeight - parseFloat($('#weight_change').data('initialWeight'));

    let more = false;
    if(weightChange > 0) {
        more = true;
    }

    // ergebnis schön machen
    weightChange = weightChange.toFixed(2).toString().replace('/./g',',');

    // nur ordentliche ergebnisse anzeigen
    if(isNaN(weightChange) || ! isFinite(weightChange)) {
        $('#weight_change').val('');
    }
    else {
        $('#weight_change').val((more ? '+' : '') + weightChange.replace('.',','));
    }

});


// range slider mit emoji anzeige
$('#motivation').on('input change', function() {
   const emojies =  ['🥴','🙁','😐','😊','😄','🥰'];

   if(emojies.indexOf([$(this).val() - 1]) !== false) {
        $('#emoji-display').html('<span class="h1">'+ emojies[$(this).val() - 1] +'</span>');
   }
});

// ALLES initialisieren
$('form input').each(function() {
    $(this).trigger('change');
    $(this).trigger('input');
})

// ajax form submit für eingaben unterhalb der videos
$('button#store-additional-user-input').on('click', function(e) {
    e.preventDefault();

    $.post(INBODY_USER_DATA_POST_URL, $('#motivation-form').serialize(), function(data) {
        // feedback anzeigen
        if(typeof data.error !== 'undefined') {
            // valdierung gescheitert
            $('#ajax-validation-feedback-bottom').removeClass('d-none');
            $('#ajax-form-success-bottom').addClass('d-none');
            $('#ajax-validation-feedback-bottom ul').empty();
            $.each(data.error, function() {
                $('#ajax-validation-feedback-bottom ul').append('<li>' + this + '</li>');
            });
        }
        else {
            // speichern erfolgreich
            $('#ajax-validation-feedback-bottom').addClass('d-none');
            $('#ajax-form-success-bottom').removeClass('d-none');
        }
    });
});

// ajax form submit für die zeitabhängingen modals
// parameter erlauben die benutzung mit allen modals
storeUserWeight = function(e) {
    e.preventDefault();

    $submitButton = $(this);

    if($(this).hasClass('disabled')) {
        return null;
    }

    $submitButton.addClass('disabled');

    $.post(e.data.postUrl, $(e.data.selector).serialize(), function(data) {
        // feedback anzeigen
        if(typeof data.error !== 'undefined') {
            // valdierung gescheitert
            $('#ajax-validation-feedback').removeClass('d-none');
            $('#ajax-validation-feedback ul').empty();
            $.each(data.error, function() {
                $('#ajax-validation-feedback ul').append('<li>' + this + '</li>');
            });
        }
        else {
            // speichern erfolgreich
            $('#ajax-validation-feedback').addClass('d-none');
            e.data.modal.modal('hide');
            e.data.player.play();
        }

        $submitButton.removeClass('disabled');
    });
};
